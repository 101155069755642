import { motion } from "framer-motion";

export default function Blob() {
  return (
    <div
      className="example"
      style={{
        border: "none",
        padding: "0",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <motion.svg
        style={{ width: "80%", height: "80%" }}
        viewBox="0 0 340 280"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-963 -870)">
        <motion.path
          initial={{ pathLength: 0, }}
          animate={{ pathLength: 0.45,  }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 1
          }}
          strokeWidth={4}
          strokeDasharray="0 1"
          stroke={"orangered"}
          fill="red"
        //   d="M415,275Q422,310,417.5,354Q413,398,378,423Q343,448,299,423Q255,398,227.5,389.5Q200,381,151,401.5Q102,422,86,383.5Q70,345,65,309.5Q60,274,78.5,243.5Q97,213,87.5,176.5Q78,140,107.5,122Q137,104,160.5,74Q184,44,222,33Q260,22,293.5,43.5Q327,65,362,81Q397,97,386,142.5Q375,188,391.5,214Q408,240,415,275Z"
          d="M963.838 1124.92 986.289 1101.26 997.808 1089.78 1009.65 1078.77 1021.92 1068.38 1034.72 1058.77 1048.17 1050.1 1062.35 1042.55 1070.02 1039.36 1078.17 1036.76 1086.71 1034.68 1095.56 1033.01 1113.79 1030.59 1132.27 1028.8 1132.26 1028.8 1150.34 1026.98 1150.27 1026.98 1158.96 1025.85 1158.88 1025.86 1167.22 1024.47 1167.11 1024.49 1175.02 1022.76 1174.87 1022.8 1182.26 1020.64 1182.07 1020.7 1188.86 1018.03 1188.62 1018.15 1194.73 1014.89 1194.47 1015.05 1199.89 1011.14 1199.67 1011.32 1204.52 1006.82 1204.34 1007 1208.67 1001.99 1208.54 1002.16 1212.41 996.715 1212.31 996.864 1215.78 991.08 1215.71 991.204 1218.85 985.16 1218.78 985.295 1224.28 972.766 1224.24 972.859 1229.11 960.293 1233.84 948.324 1236.33 942.718 1238.99 937.493 1241.89 932.718 1245.11 928.497 1251.99 921.369 1259.28 915.171 1266.8 909.832 1276.21 905.042 1278.29 909.126 1269.03 913.84 1269.32 913.667 1262.01 918.85 1262.17 918.728 1255.04 924.788 1255.21 924.633 1248.5 931.583 1248.67 931.38 1245.61 935.398 1245.75 935.2 1242.95 939.799 1243.04 939.648 1240.45 944.743 1240.5 944.635 1238.05 950.143 1238.08 950.054 1233.37 961.971 1233.38 961.955 1228.5 974.561 1222.95 987.204 1219.75 993.377 1216.2 999.299 1212.21 1004.9 1207.73 1010.09 1202.69 1014.78 1197.02 1018.86 1190.66 1022.25 1183.65 1025.01 1176.08 1027.22 1168.03 1028.98 1159.6 1030.39 1150.83 1031.53 1132.72 1033.36 1114.27 1035.14 1114.35 1035.14 1096.23 1037.54 1096.35 1037.52 1087.62 1039.17 1087.74 1039.14 1079.34 1041.2 1079.49 1041.15 1071.51 1043.69 1071.69 1043.63 1064.21 1046.74 1064.41 1046.64 1050.41 1054.1 1050.57 1054.01 1037.28 1062.57 1037.41 1062.48 1024.72 1072 1024.83 1071.92 1012.65 1082.23 1012.73 1082.16 1000.96 1093.11 1001.01 1093.06 989.546 1104.48 989.592 1104.43 967.163 1128.08ZM1267.85 896.052 1298.5 898.5 1278.15 921.55Z"
        />
        {/* <motion.path
          initial={{opacity:0 }}
          animate={{  opacity:1 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "loop",
            repeatDelay: 1
          }}
          strokeWidth={4}
          strokeDasharray="0 1"
          stroke={"orangered"}
          fill="orangered"
        //   d="M415,275Q422,310,417.5,354Q413,398,378,423Q343,448,299,423Q255,398,227.5,389.5Q200,381,151,401.5Q102,422,86,383.5Q70,345,65,309.5Q60,274,78.5,243.5Q97,213,87.5,176.5Q78,140,107.5,122Q137,104,160.5,74Q184,44,222,33Q260,22,293.5,43.5Q327,65,362,81Q397,97,386,142.5Q375,188,391.5,214Q408,240,415,275Z"
          d="M963.838 1124.92 986.289 1101.26 997.808 1089.78 1009.65 1078.77 1021.92 1068.38 1034.72 1058.77 1048.17 1050.1 1062.35 1042.55 1070.02 1039.36 1078.17 1036.76 1086.71 1034.68 1095.56 1033.01 1113.79 1030.59 1132.27 1028.8 1132.26 1028.8 1150.34 1026.98 1150.27 1026.98 1158.96 1025.85 1158.88 1025.86 1167.22 1024.47 1167.11 1024.49 1175.02 1022.76 1174.87 1022.8 1182.26 1020.64 1182.07 1020.7 1188.86 1018.03 1188.62 1018.15 1194.73 1014.89 1194.47 1015.05 1199.89 1011.14 1199.67 1011.32 1204.52 1006.82 1204.34 1007 1208.67 1001.99 1208.54 1002.16 1212.41 996.715 1212.31 996.864 1215.78 991.08 1215.71 991.204 1218.85 985.16 1218.78 985.295 1224.28 972.766 1224.24 972.859 1229.11 960.293 1233.84 948.324 1236.33 942.718 1238.99 937.493 1241.89 932.718 1245.11 928.497 1251.99 921.369 1259.28 915.171 1266.8 909.832 1276.21 905.042 1278.29 909.126 1269.03 913.84 1269.32 913.667 1262.01 918.85 1262.17 918.728 1255.04 924.788 1255.21 924.633 1248.5 931.583 1248.67 931.38 1245.61 935.398 1245.75 935.2 1242.95 939.799 1243.04 939.648 1240.45 944.743 1240.5 944.635 1238.05 950.143 1238.08 950.054 1233.37 961.971 1233.38 961.955 1228.5 974.561 1222.95 987.204 1219.75 993.377 1216.2 999.299 1212.21 1004.9 1207.73 1010.09 1202.69 1014.78 1197.02 1018.86 1190.66 1022.25 1183.65 1025.01 1176.08 1027.22 1168.03 1028.98 1159.6 1030.39 1150.83 1031.53 1132.72 1033.36 1114.27 1035.14 1114.35 1035.14 1096.23 1037.54 1096.35 1037.52 1087.62 1039.17 1087.74 1039.14 1079.34 1041.2 1079.49 1041.15 1071.51 1043.69 1071.69 1043.63 1064.21 1046.74 1064.41 1046.64 1050.41 1054.1 1050.57 1054.01 1037.28 1062.57 1037.41 1062.48 1024.72 1072 1024.83 1071.92 1012.65 1082.23 1012.73 1082.16 1000.96 1093.11 1001.01 1093.06 989.546 1104.48 989.592 1104.43 967.163 1128.08ZM1267.85 896.052 1298.5 898.5 1278.15 921.55Z"
        /> */}
      </g>
      </motion.svg>
    </div>
  );
}