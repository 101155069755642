import React from "react";
import logo from "./logo.svg";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import LogoFirma from "./LogoFirma";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
export function Header() {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (navigateTo:string|undefined) => {
    if (navigateTo!==undefined && navigateTo !== ''){

      navigate(navigateTo);
    }
    setOpenMenu(false);
    setAnchorEl(null);
  };

  const isBigScreen = useMediaQuery({ query: "(min-width: 950px)" });

  return (
    <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
      <LogoFirma></LogoFirma>
      <Box sx={{ flexGrow: 1 }}></Box>
      {!isBigScreen && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ flex: 1 }}></Box>
          <IconButton onClick={handleClick}>
            <MenuIcon></MenuIcon>
          </IconButton>
          <Box sx={{ flex: 1 }}></Box>
        </Box>
      )}
      {isBigScreen && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flex: 1 }}></Box>
            {/* <Button variant="outlined">Kontakt</Button> */}
            <Link href="/#service" sx={{color:"black", fontSize:20, fontWeight:'bold', ":hover":{textDecorationLine:'underlined'}}}>Service</Link>
            {/* <Typography sx={{}}>Service</Typography> */}
            <Box sx={{ flex: 1 }}></Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flex: 1 }}></Box>
            {/* <Button variant="outlined">Kontakt</Button> */}
            <Link href="/#projects" sx={{color:"black", fontSize:20, fontWeight:'bold'}}>Projekte</Link>
            {/* <Typography sx={{}}>Projekte</Typography> */}
            <Box sx={{ flex: 1 }}></Box>
          </Box>
          {/* <Typography sx={{}}>Kunden</Typography> */}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flex: 1 }}></Box>
            {/* <Button variant="outlined">Kontakt</Button> */}
            <Link href="impressum" sx={{color:"black", fontSize:20, fontWeight:'bold'}}>Impressum</Link>
            {/* <Typography sx={{}}>Impressum</Typography> */}
            <Box sx={{ flex: 1 }}></Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ flex: 1 }}></Box>
            <Button variant="outlined" sx={{}} href="mailto:tobi.staehle@web.de?subject=Kontaktanfrage">Kontakt</Button>
            <Box sx={{ flex: 1 }}></Box>
          </Box>
        </Box>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={()=>{handleClose(undefined)}}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: 250,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={()=>{handleClose('/')}}>Home</MenuItem>
        <Link href="/#service" sx={{textDecoration: 'none', color:'black'}}><MenuItem onClick={()=>{handleClose('')}}>Service</MenuItem></Link>
        <Link href="/#projects" sx={{textDecoration: 'none', color:'black'}}><MenuItem onClick={()=>{handleClose('')}}>Projekte</MenuItem></Link>
        <Divider></Divider>
        <MenuItem onClick={()=>{handleClose('/impressum')}}>Impressum</MenuItem>
        <MenuItem onClick={()=>{handleClose('/datenschutz')}}>Datenschutz</MenuItem>
        <Divider></Divider>
        <Button variant="outlined" sx={{ml:2}} href="mailto:tobi.staehle@web.de?subject=Kontaktanfrage">Kontakt</Button>
        {/* <MenuItem onClick={()=>{
          // handleClose('/')
          }} href="mailto:tobi.staehle@web.de?subject=Kontaktanfrage">Kontakt</MenuItem> */}
      </Menu>
    </Box>
  );
}
