import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Header } from './Header';
import HeroSection from './HeroSection';
import Footer from './Footer';
import { Box } from '@mui/material';
import SectionService from './SectionService';
import SectionProjects from './SectionProjects';

function MainPage() {
  return (
    <>
      
      <HeroSection></HeroSection>
      <SectionService></SectionService>
      <SectionProjects></SectionProjects>
    </>
      
  );
}

export default MainPage;
