import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Header } from './Header';
import HeroSection from './HeroSection';
import Footer from './Footer';
import { Box } from '@mui/material';
import SectionService from './SectionService';
import MainPage from './MainPage';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import Datenschutz from "./Datenschutz";
import Impressum from "./Impressum";

const router =[
  {
    path: "/",
    element: (
      <MainPage />
    ),
  },
  {
    path: "about",
    element: <div>About</div>,
  },
  {
    path: "datenschutz",
    element: <Datenschutz></Datenschutz>,
  },
  {
    path: "impressum",
    element: <Impressum></Impressum>,
  },
];  

function App() {


  return (
    <div className="App">
      <Box sx={{ display: "flex", flexDirection: "column", flex:1 }}></Box>
      <Header></Header>
      {/* <MainPage></MainPage> */}
      {/* <RouterProvider router={router} /> */}
      <Routes>
        {router.map((route) => (
          <Route
            path={route.path}
            element={route.element}
          />
        )
        )}
      </Routes>
      <Box
        sx={{flex:1}}      ></Box>
      <Footer></Footer>
      {/* Test
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
