import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Header } from './Header';
import HeroSection from './HeroSection';
import Footer from './Footer';
import { Box, Typography } from '@mui/material';

function LogoFirma() {
  return (
    <Box>
        <Typography
          variant="h1"
          sx={{ flexGrow: 1, fontSize: 65, textTransform: "capitalize" }}
        >
          TOBIAS
        </Typography>
        <Typography variant="h1" sx={{ flexGrow: 1, fontSize: 25 }}>
          STÄHLE SOFTWARE
        </Typography>
      </Box>
  );
}

export default LogoFirma;



