import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
interface IServiceCardProps {
  img: string;
  description: string;
  title: string;
  minHeight?: number;
}
export default function MediaCard(props: IServiceCardProps) {
  const { title, description, img,minHeight } = props;
  return (
    <Box sx={{m:2,height:'100%'}}>
      <Card sx={{  maxWidth: 345, margin: 'auto',minHeight:(minHeight!==undefined?minHeight:350) }}>
        <CardMedia sx={{ height: 140 }} image={img} title={title} component="img" loading="lazy" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{}}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          {/* <Button size="small">Share</Button> */}
          {/* <Button size="small">Learn More</Button> */}
        </CardActions>
      </Card>
    </Box>
  );
}
