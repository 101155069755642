import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Header } from "./Header";
import HeroSection from "./HeroSection";
import Footer from "./Footer";
import { Box } from "@mui/material";
import SectionService from "./SectionService";

function Impressum() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1,textAlign:"left" }}>
      <h1>Impressum</h1>

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Tobias St&auml;hle
        <br />
        Mundelsheimer Str. 16
        <br />
        74074 Heilbronn
      </p>

      <h2>Kontakt</h2>
      <p>
        {/* Telefon: &#91;Telefonnummer&#93;
        <br /> */}
        E-Mail: tobi.staehle@web.de
      </p>

      <h2>Umsatzsteuer-ID</h2>
      <p>
        Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
        Umsatzsteuergesetz:
        <br />
        DE361862454
      </p>

      <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
      <p>
        <strong>Name und Sitz des Versicherers:</strong>
        <br />
        Hiscox
        <br />
        Arnulfstra&szlig;e 31, D - 80636 M&uuml;nchen
        <br />
        T +49 (0)89 545801-100 <br />
        F +49 (0)89 545801-199
        <br />E hiscox.info@hiscox.de 
        <br />www.hiscox.de
      </p>
      <p>
        <strong>Geltungsraum der Versicherung:</strong>
        <br />
        weltweit
      </p>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europ&auml;ische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:{" "}
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ec.europa.eu/consumers/odr/
        </a>
        .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2>
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <p>
        Quelle:{" "}
        <a href="https://www.e-recht24.de/impressum-generator.html">
          https://www.e-recht24.de/impressum-generator.html
        </a>
      </p>
    </Box>
  );
}

export default Impressum;
