import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Box, Typography } from "@mui/material";
import { Image } from "@mui/icons-material";
import Blob from "./Blob";
import { useMediaQuery } from "react-responsive";
function HeroSection() {
  const isNotSmartphone = useMediaQuery({ query: "(min-width: 1100px)" });
  return (
    <>
      {/* <Box
        sx={{ display: "flex", flexDirection: "row", flex: 1, height: "5vh" }}
      ></Box> */}
      <Box sx={{ display: "flex", flexDirection: isNotSmartphone?"row":"column-reverse", flex: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>

        <Box sx={{ flex: 1 }}></Box>
        <Typography variant="h4">
          Visionen sind Träume,
          <br /> die mit uns zur Realität werden.
        </Typography>
        <Box sx={{ flex: 1 }}></Box>
        </Box>
        <Box sx={{ flex: 1 }}></Box>
        {/* <Typography variant='h4'>
            Jeder hat Visionen, wir helfen Ihnen diese zu verwirklichen.
        </Typography> */}
        <Box sx={{ minWidth: 'min(500px, 50vw)', flex:1, width:500, maxWidth:'min(700px, 80vw)', margin:'auto',minHeight:20 }}>
          <Blob></Blob>
        </Box>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
      </Box>
      <Box sx={{height:isNotSmartphone?0:100}}></Box>
    </>
  );
}

export default HeroSection;
