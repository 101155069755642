import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Header } from './Header';
import HeroSection from './HeroSection';
import Footer from './Footer';
import { Box, Typography } from '@mui/material';
import ServiceCard from './ServiceCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
function SectionService() {
  
const serviceList = [
    {
        title: "Daten-Analyse und KI-Modelle",
        description: "Wir entwickeln für Sie KI-Modelle und Daten-Analyse-Tools, die Ihnen helfen, Ihre Daten schnell zu verstehen und effektiv zu nutzen.",
        img: "pexels-photomix-company-95916.jpg"
    },
    {
        title: "Web-Applikationen",
        description: "Wir programmieren auf Ihren Wunsch und Bedarf zugeschnittene Web-Applikationen, die es Ihnen ermöglichen von jedem Ort auf der Welt auf Ihre Daten zuzugreifen und Web-Services für Ihr Unternehmen zur Verfügung zu stellen.",
        img: "pexels-mikael-blomkvist-6476587.jpg"
    },
    {
        title: "Softwareentwicklung",
        description: "Auch bei Bedarf an reiner Softwareentwicklung sind Sie bei uns an der richtigen Adresse. Wir entwickeln für Sie Software, die genau auf Ihre Bedürfnisse zugeschnitten ist und Ihnen hilft, Ihre Visionen zu verwirklichen.",
        img: "pexels-luis-gomes-546819.jpg"
    },
    {
        title: "Prototyping",
        description: "Sie benötigen einen Protoypen, um Ihre Ideen zu testen? Wir helfen Ihnen dabei, Ihre Ideen schnell und effizient zu testen und zu verwirklichen.",
        img: "pexels-senne-hoekman-1178498.jpg"
    },
    {
        title: "IT-Beratung",
        description: "Sie möchten zu Ihren IT-Projekten von einem Experten beraten werden? Wir beraten Sie zu Ihren Projekten, damit Sie diese erfolgreich umzusetzen können und Ihre Visionen Realität werden.",
        img: "pexels-leeloo-thefirst-5428830.jpg"
    },
    
   
   
];
const isNotSmartphone = useMediaQuery({ query: "(min-width: 1100px)" });
const settings = {
  dots: true,
  infinite: true,
  slidesToShow: isNotSmartphone?3:1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 10000,
  cssEase: "linear",
 
};
  return (
    <Box sx={{minHeight:250, mt:3,mb:3}}>
        <Typography sx={{textAlign:"left",mb:2}} id="service" variant='h4'>Service</Typography>
        {/* Comming soon... */}
        {/* <Box sx={{display:"flex",flexDirection:"row", width:"100%",justifyContent:"space-between",mb:2}}>
            <ServiceCard></ServiceCard>
            <ServiceCard></ServiceCard>
            <ServiceCard></ServiceCard>
        </Box> */}
    {/* <h2>Auto Play</h2> */}
        <Slider {...settings}>
            {serviceList.map((item, index) => (
                <ServiceCard key={index} title={item.title} description={item.description} img={item.img}></ServiceCard>
            ))}
        </Slider>
    </Box>
  );
}

export default SectionService;
