import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Header } from './Header';
import HeroSection from './HeroSection';
import Footer from './Footer';
import { Box, Typography } from '@mui/material';
import ServiceCard from './ServiceCard';
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";

function SectionProjects() {
  const serviceList = [
    {
        title: "Datenplattform",
        description: "Dank der von uns entwickelten Datenplattform kann unser Kunde von überall auf seine Daten und Dokumente zugreifen und kann diese mit seinen Kunden teilen. Die Datenplattform ist eine Web-Applikation, die auf den Bedarf unseres Kunden zugeschnitten ist und ihm hilft, seine Daten und Dokumente effizient zu nutzen. Unsere flexible Lösung passt sich in die bestehende Datenlandschaft des Kunden ein und erweitert sein bestehendes System um eine Cloudlösung.",
        img: "pexels-photomix-company-95916.jpg"
    },
    {
        title: "Routenplanung für Logistik-Unternehmen",
        description: "Unsere Applikation ermöglicht es unserem Kunden die Routen für die Fahrer seines Unternehmens zeit- und kosteneffizient zu planen. Die Applikation ist als Web-App programmiert und ist somit weltweit erreichbar und lässt sich dank Cloud-Technologie einfach skalieren.",
        img: "Routeplanning.jpeg"
    },
    {
        title: "Rezept- und Überweisungstool für Ärzte",
        description: "Dieser Service ermöglicht es den Patienten unserer Kunden online auf der Website der Arztpraxen Rezepte und Überweisungen zu bestellen. Wir setzen bei der Entwicklung auf die neuesten Technologien und Verschlüsselungsstandards, damit die Daten sicher sind. Die Applikation enthält eine Schnittstelle zu den Praxisverwaltungssystemen der Ärzte. Die Patienten werden automatisch per E-Mail benachrichtigt, sobald das bestellte Rezept/Überweisung abholbereit ist.",
        img: "pexels-picjumbocom-196644.jpg"
    },
    
    
   
   
];
const isNotSmartphone = useMediaQuery({ query: "(min-width: 1100px)" });
const settings = {
  dots: true,
  infinite: true,
  slidesToShow: isNotSmartphone?3:1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 10000,
  cssEase: "linear",
 
};
  return (
    <Box sx={{minHeight:250, mt:3,mb:3}}>
        <Typography sx={{textAlign:"left",mb:2}} id="projects" variant='h4'>Projekte</Typography>
        {/* Comming soon... */}
        {/* <Box sx={{display:"flex",flexDirection:"row", width:"100%",justifyContent:"space-between",mb:2}}>
            <ServiceCard></ServiceCard>
            <ServiceCard></ServiceCard>
            <ServiceCard></ServiceCard>
        </Box> */}
    {/* <h2>Auto Play</h2> */}
        <Slider {...settings}>
            {serviceList.map((item, index) => (
                <ServiceCard key={index} title={item.title} description={item.description} img={item.img} minHeight={470}></ServiceCard>
            ))}
        </Slider>
    </Box>
  );
}

export default SectionProjects;
