import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Header } from "./Header";
import HeroSection from "./HeroSection";
import { Box, Divider, Link, Typography } from "@mui/material";
import LogoFirma from "./LogoFirma";
import { Scale } from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";
function Footer() {
  const isBigScreen = useMediaQuery({ query: "(min-width: 900px)" });
  const isNotSmartphone = useMediaQuery({ query: "(min-width: 600px)" });
  // const isSmartphone = useMediaQuery({ query: "(max-width: 400px)" });
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isNotSmartphone?"row":"column",
          flex: 1,
          height: "5vh",
        }}
      >
        {isBigScreen && (
          <>
            <Box
              sx={{ display: "flex", flexDirection: "column", flex: 1, p: 2 }}
            >
              {/* <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}></Box> */}
              <Box sx={{ transform: "scale(0.5)" }}>
                <LogoFirma></LogoFirma>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "row", flex: 1 }}
              ></Box>
            </Box>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        {!isNotSmartphone && (
        <Divider orientation={"horizontal"} flexItem />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            textAlign: "left",
            p: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Kontakt
          </Typography>
          <Typography>Tobias Stähle</Typography>
          <Typography>tobi.staehle@web.de</Typography>
          <Typography>Mundelsheimer Str. 16</Typography>
          <Typography>74074 Heilbronn</Typography>
        </Box>
        <Divider orientation={isNotSmartphone?"vertical":"horizontal"} flexItem />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            textAlign: "left",
            p: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Mehr
          </Typography>
          <Link href="impressum">Impressum</Link>
          <Link href="datenschutz">Datenschutz</Link>
        </Box>
      </Box>
      <Box sx={{ height: "2vh" }}></Box>
    </>
  );
}

export default Footer;
